<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button v-permission="'SystemAuthAddButton'" status="my-orange" icon="fa fa-plus" @click="handleAdd()">添加</vxe-button>
      </template>
    </vxe-toolbar>
    <CustomList
      ref="CustomList"
      :border="false"
      row-key="id"
      :tree-props="{hasChildren: 'hasChildren', children: 'child' }"
      :columns="tableColumns"
      @getList="getList"
    >
      <template slot="operating" slot-scope="{row}">
        <el-button v-permission="'SystemAuthAddButton'" size="small" type="text" @click="handleAdd(row)">添加</el-button>
        <el-button v-permission="'SystemAuthEditButton'" size="small" type="text" @click="handleUpdate(row)">编辑</el-button>
        <el-button v-if="row.child.length === 0" v-permission="'SystemAuthDeleteButton'" size="small" type="text" @click="handleDelete(row.id)">删除</el-button>
      </template>
    </CustomList>
    <Form ref="Form" @submit="customListGetList()" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CustomList from '@/components/CustomList/index'
import Form from './components/form'
export default {
  name: 'Auth',
  components: { Form, CustomList },
  data() {
    return {
      tableColumns: [
        {
          prop: 'title',
          title: '标题',
          align: 'left',
          headerAlign: 'left'
        },
        {
          prop: 'name',
          title: '标识',
          align: 'left',
          headerAlign: 'left'
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'operating',
          minWidth: 150
        }
      ]
    }
  },
  methods: {
    ...mapActions(['authList', 'authDelete']),
    customListGetList() {
      this.$refs.CustomList.getList(true)
    },
    getList(params, callback) {
      this.authList(params).then(response => {
        response.data = {
          records: response.data
        }
        callback(response)
      }).catch(() => {})
    },
    handleAdd(row = null) {
      this.$refs.Form.handleAdd(row)
    },
    handleUpdate(row) {
      this.$refs.Form.handleEdit(row)
    },
    handleDelete(id) {
      this.$confirm('确认删除吗？', '', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.authDelete({ id }).then(res => {
          if (res.code !== 200) {
            this.$message.error(res.msg)
            return
          }
          this.$message.success('删除成功')
          this.customListGetList()
        }).catch(() => {})
      }).catch(() => {})
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper{
    overflow: auto;
  }
</style>
