<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    />
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
export default {
  name: 'Form',
  components: { CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      parentData: null,
      currentData: null,
      formStatus: '',
      formModel: {
        pid: 0,
        title: '',
        name: '',
        level: 0,
        status: 1
      }
    }
  },
  computed: {
    formItem() {
      return {
        'title': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '标题',
          'maxlength': 100
        },
        'name': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '标识',
          'maxlength': 255
        },
        'path': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '路由地址',
          'maxlength': 255
        },
        'component': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '模板文件的相对位置',
          'maxlength': 255
        },
        'key': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '路由名称',
          'maxlength': 255
        },
        'redirect': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '重定向地址',
          'maxlength': 255
        },
        'icon': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '菜单图标',
          'maxlength': 255
        },
        'sort': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '排序 （数字越大越靠前）',
          'maxlength': 255
        },
        'keepAlive': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '是否叶子结点（1 为是 0 为 不是）',
          'maxlength': 255
        }
      }
    },
    formRules() {
      return {
        name: [
          { required: true, message: '标识不能为空', trigger: ['blur', 'change'] }
        ],
        title:[
        { required: true, message: '标题不能为空', trigger: ['blur', 'change'] },
        ]
      }
    }
  },
  methods: {
    ...mapActions(['authAdd', 'authUpdate']),
    handleAdd(parentData) {
      this.init('add', '', parentData)
    },
    handleEdit(currentData) {
      this.init('edit', currentData)
    },
    init(formStatus, currentData = null, parentData = null) {
      Object.assign(this, this.$options.data.call(this))
      this.$refs.CustomDrawer.handleOpen()
      this.currentData = currentData
      this.parentData = parentData
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      if (this.currentData) {
        this.formModel['id'] = this.currentData.id
        this.formModel['pid'] = this.currentData.pid
        this.formModel['title'] = this.currentData.title
        this.formModel['name'] = this.currentData.name
        this.formModel['level'] = this.currentData.level
        this.formModel['status'] = this.currentData.status

        this.formModel['component'] = this.currentData.component
        this.formModel['path'] = this.currentData.path
        this.formModel['key'] = this.currentData.key
        this.formModel['redirect'] = this.currentData.redirect
        this.formModel['icon'] = this.currentData.icon
        this.formModel['keepAlive'] = this.currentData.keepAlive
        this.formModel['sort'] = this.currentData.sort
      } else {
        if (this.parentData) {
          this.formModel['pid'] = this.parentData.id
          this.formModel['level'] = this.parentData.level + 1
        }
      }
      this.$refs.CustomForm.$refs.form.clearValidate()
      this.$refs.CustomDrawer.closeLoading()
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          switch (this.formStatus) {
            case 'add':
              this.authAdd(this.formModel).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.authUpdate(this.formModel).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
